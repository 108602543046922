@font-face {
	font-family: "PIZZA";
	src: url("./assets/fonts/hotpizza.woff2") format("truetype");
	font-display: swap;
}

:root {
	--firstColor: #f20530;
	--secondColor: #0a731a;
	--frontColor: #1b1b1b;
	--backColor: #f2f2f2;
	--largeur: 70%;
	--maxLargeur: 1332px;
}

html,
body {
	position: relative;
	height: 100%;
}

html {
	scroll-behavior: smooth;
}

body {
	text-align: justify;
	color: var(--frontColor);
	margin: 0;
}

#root {
	height: 100%;
}

body,
select,
option,
input {
	font-family: "Source Sans Pro", sans-serif;
}

input,
select {
	background-color: var(--backColor);
}

main {
	position: relative;
	-webkit-transition: padding 250ms ease-in-out;
	-o-transition: padding 250ms ease-in-out;
	transition: padding 250ms ease-in-out;
}

/* body p,
label,
select,
input {
	font-size: 16px;
} */

/* h2 {
	font-size: 40px;
	margin: 0;
	margin-bottom: 1.5em;
} */

button,
.btn {
	width: fit-content;
	height: fit-content;
}

.btn:not(.remove-pizza):not(.add-pizza):not(.add-boisson):not(.remove-boisson):not(.add-dessert):not(.remove-dessert) svg {
	margin-right: 0.5em;
}

.top {
	top: 1em;
}

.bottom {
	bottom: 1em;
}

.left {
	left: 1em;
}

.right {
	right: 1em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	font-weight: bold;
	text-align: left;
}

.page-largeur {
	width: var(--largeur);
	max-width: var(--maxLargeur);
	margin: 0 auto;
}

.invalid-feedback {
	font-size: 100% !important;
}

.close-popup {
	position: absolute;
	right: 10px;
	top: 5px;
	cursor: pointer;
}

/*                                                     UNDERLINE H TITLE*/

.line {
	padding-left: 5px;
	position: relative;
	z-index: 0;
}

.line:after {
	z-index: -1;
	content: "";
	opacity: 0.66;
	width: calc(100% + 5px);
	height: 7.5px;
	position: absolute;
	bottom: 5px;
	left: 0px;
}

.line:after {
	height: 10px;
}

.line.green:after {
	background-color: var(--secondColor);
}

.line.red:after {
	background-color: var(--firstColor);
}

/*----------------------------------------------------------------------*/

/*                                                       LINK HOVER LINE*/

.link,
.link:hover {
	text-decoration: none !important;
	color: var(--frontColor);
}

.link:after {
	position: relative;
	display: block;
	content: "";
	background-color: var(--frontColor);
	height: 2px;
	border-radius: 25px;
	-webkit-transform: scaleX(0);
	-ms-transform: scaleX(0);
	transform: scaleX(0);
	-webkit-transition: all 250ms ease-in-out;
	-o-transition: all 250ms ease-in-out;
	transition: all 250ms ease-in-out;
}

.link:hover:after,
header .nav .active:after {
	-webkit-transform: scaleX(1);
	-ms-transform: scaleX(1);
	transform: scaleX(1);
}

/* .red,
rouge,
.red:hover { */
.rouge,
rouge {
	color: var(--firstColor);
}

/* .rouge::after {
		background-color: var(--firstColor);
	} */

vert {
	color: var(--secondColor);
}

.bleu,
bleu {
	color: blue;
}

/*----------------------------------------------------------------------*/

/*                                                          CLOSE CROSS*/

.bar {
	width: 35px;
	height: 3px;
	background-color: var(--frontColor);
	margin: 8px 0;
	-webkit-transition: 300ms;
	-o-transition: 300ms;
	transition: 300ms;
	border-radius: 20px;
}

.change .one {
	-webkit-transform: rotate(-45deg) translate(-9px, 6px);
	-ms-transform: rotate(-45deg) translate(-9px, 6px);
	transform: rotate(-45deg) translate(-9px, 6px);
}

.change .two {
	opacity: 0;
}

.change .three {
	-webkit-transform: rotate(45deg) translate(-9px, -7px);
	-ms-transform: rotate(45deg) translate(-9px, -7px);
	transform: rotate(45deg) translate(-9px, -7px);
}

/*----------------------------------------------------------------------*/

/*                                                         SHAPE DIVIDER*/

.shape {
	overflow: hidden;
	position: relative;
	z-index: 2;
}

.poly {
	width: 100%;
	height: 50px;
	-webkit-clip-path: polygon(0 0, 100% 0, 50% 100%, 0 0);
	clip-path: polygon(0 0, 100% 0, 50% 100%, 0 0);
	-webkit-transform: scale(1.01);
	-ms-transform: scale(1.01);
	transform: scale(1.01);
}

.backColor {
	background-color: var(--backColor);
}

.white {
	background-color: white;
}

.frontColor {
	background-color: var(--frontColor);
}

/*----------------------------------------------------------------------*/

/*                                                  FORM SIGN IN SIGN UP*/

.form-container {
	background-color: white;
	padding: 2em;
	/*margin: 0 2em;*/
	border-radius: 5px;
	-webkit-box-shadow: 0 2px 5px #bbb;
	box-shadow: 0 2px 5px #bbb;
}

.alert {
	font-size: unset !important;
}

.alert p {
	margin: 0;
	font-size: unset !important;
}

/*----------------------------------------------------------------------*/

/*                                                            POPUP BASE*/

.popup-container {
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(27, 27, 27, 0.7);
	/* display: none; */
}

.popup-container,
.popup-flexbox {
	width: 100%;
	height: 100%;
	z-index: 1001;
}

.popup-flexbox {
	overflow-y: auto;
	overflow-x: hidden;
	display: flex;
	align-items: center;
	padding: 1em 0;
}

.popup {
	display: block;
	margin: auto;
	position: relative;
	height: fit-content;
	/*left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);*/
	-webkit-box-shadow: 5px 5px 10px var(--frontColor);
	box-shadow: 5px 5px 10px var(--frontColor);
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	border-radius: 5px;
}

/*----------------------------------------------------------------------*/

/*                                                                SWAL2 */
.swal2-title {
	width: 100%;
}
/*----------------------------------------------------------------------*/

@media (max-width: 1200px) {
	:root {
		--largeur: 82.5%;
	}
}

@media (max-width: 992px) {
	:root {
		--largeur: 90%;
	}
}

@media (max-width: 768px) {
	/* h2 {
		margin-left: auto;
		margin-right: auto;
	} */
	.line {
		padding-left: 0;
	}
	.line:after {
		bottom: 5px;
		left: -2.5px;
	}
}

@media (max-width: 600px) {
	/* body p,
	label,
	select,
	input {
		font-size: 17px;
	} */
}

@media (max-width: 480px) {
	/* h2 {
		font-size: 32.5px;
	} */
	.line:after {
		height: 7.5px;
	}
}

@media print {
	.no-print,
	.no-print * {
		display: none !important;
	}

	/* fix second page blank */
	html,
	body {
		height: fit-content;
	}

	.command-box {
		width: 450px;
	}

	.command-data {
		display: block !important;
	}
}
