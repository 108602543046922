/*                                                           PAGE LAOGER*/
#loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--frontColor);
	color: var(--backColor);
	z-index: 9999;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	font-size: 16px;
}

#loader h1 {
	color: var(--backColor);
	text-transform: uppercase;
	margin-top: 2em;
	font-family: "PIZZA", "Source Sans Pro", sans-serif;
	font-size: 3em;
	font-weight: lighter;
}

#loader img {
	-webkit-animation: p-load 1s infinite linear;
	animation: p-load 1s infinite linear;
}

@-webkit-keyframes p-load {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes p-load {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@media (max-width: 992px) {
	#loader h1 {
		font-size: 2em;
	}
	#loader img {
		width: 128px;
	}
}

@media (max-width: 480px) {
	#loader h1 {
		font-size: 1.25em;
	}
	#loader img {
		width: 80px;
	}
}
